import { Navigate } from "react-router-dom";


export const Protection = ({ children }) => {
    
    const authentication = localStorage.getItem("TOKEN");
    const user = localStorage.getItem("USER");
        
    if (authentication !== "" && user === "administrador" ) {
      return children
    }
      
    return <Navigate to="/" />
  }