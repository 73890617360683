import React from 'react';
import { BsGithub, BsYoutube, BsFacebook, BsTwitter } from "react-icons/bs";
import { GiRaceCar } from "react-icons/gi";

const Footer = () => {
  return (
    <div style={{background: 'linear-gradient(#90A4AE,white)'}} className='bg-dark' >
            <div align='center' className='p-3'>
                <a className='m-5' href='#'><BsFacebook style={{ fontSize: '40px', color: "black" }} /></a>
                <a className='m-5' href='#'><BsGithub style={{ fontSize: '40px', color: "black" }} /></a>
                <a className='m-5' href='#'><BsYoutube style={{ fontSize: '40px', color: "black" }} /></a>
                <a className='m-5' href='#'><BsTwitter style={{ fontSize: '40px', color: "black" }} /></a>
            </div>
            
            <div className='text-center text-black p-3' style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}>
                <marquee direction="right">© 2022 <GiRaceCar style={{ fontSize: '40px', color: "black"}} /></marquee>
            </div>
        </div>
  )
}

export default Footer