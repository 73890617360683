import React, { useEffect, useState } from 'react'
import { Carousel, Card, CardGroup, Table, ListGroup, Button } from 'react-bootstrap'
import { BsArchive } from 'react-icons/bs'
import picture from '../imagen/carro.jpg'
import picture2 from '../imagen/kia.jpg'
import picture3 from '../imagen/via40.jpg'
import picture4 from '../imagen/barranquilla.jpeg'
import picture5 from '../imagen/cartagena.jpeg'
import picture6 from '../imagen/alemana.jpg'
import picture7 from '../imagen/sportage.jpg'
import axios from 'axios'

const Principal = () => {


  const [equipos, setEquipos] = useState([])

  useEffect(() => {
    axios.get("http://autosfujiyama.com:3050/equipos")
      .then((response) => {
        setEquipos(response.data)
      })
    console.log("Esteban Silvera")
  }, [])

  let via, catedral, cartagena, alemana = [];

  catedral = equipos.filter(response => response.id_sede === 1 && response.eliminado === 0)
  via = equipos.filter(response => response.id_sede === 2 && response.eliminado === 0)
  cartagena = equipos.filter(response => response.id_sede === 3 && response.eliminado === 0)
  alemana = equipos.filter(response => response.id_sede === 4 && response.eliminado === 0)

  return (
    
    <div style={{ width: '100%', backgroundColor: '#90A4AE' }}>
      <div style={{ background: 'linear-gradient( #90A4AE, black , #90A4AE)' }}>
        <Carousel className="container" >
          <Carousel.Item>
            <img
              style={{ width: '100%', height: '60vh' }}
              src={picture}
              alt="First slide"
            />
            <Carousel.Caption>
              <h3>Rio Sedán</h3>
              Deja que la vida te sorprenda cada día
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              src={picture2}
              style={{ width: '100%', height: '60vh' }}
              alt="Second slide"
            />
          </Carousel.Item>
          <Carousel.Item>
            <img
              src={picture7}
              style={{ width: '100%', height: '60vh' }}
              alt="Third slide"
            />

            <Carousel.Caption>
              <h3>New Sportage</h3>
              
                Amplía tus horizontes. Construida para una inspiración infinita
              
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
      </div>

      <br />

      <div className='container'>
        <Card>
          <h1 align='center'>Computador recien agregado en cada sede</h1>
        </Card>

        <CardGroup>
          <Card>
            <Card.Img variant="top" src={picture3} width={180}
              height={300} />
            <Card.Body>
              <Card.Title>Sede Via 40</Card.Title>

              <ListGroup.Item>id: <strong>{via[via.length - 1]?.id}</strong></ListGroup.Item>
              <ListGroup.Item>nombre: <strong>{via[via.length - 1]?.nombre}</strong></ListGroup.Item>
              <ListGroup.Item>cargo: <strong>{via[via.length - 1]?.cargo}</strong></ListGroup.Item>
              <ListGroup.Item>responsable: <strong>{via[via.length - 1]?.responsable}</strong></ListGroup.Item>

            </Card.Body>
            <Card.Footer>
              <small className="text-muted">Last updated 3 mins ago</small>
            </Card.Footer>
          </Card>
          <Card>
            <Card.Img variant="top" src={picture4} width={180}
              height={300} />
            <Card.Body>
              <Card.Title>Sede Catedral</Card.Title>
              
                <ListGroup.Item>id: <strong>{catedral[catedral.length - 1]?.id}</strong></ListGroup.Item>
                <ListGroup.Item>nombre: <strong>{catedral[catedral.length - 1]?.nombre}</strong></ListGroup.Item>
                <ListGroup.Item>cargo: <strong>{catedral[catedral.length - 1]?.cargo}</strong></ListGroup.Item>
                <ListGroup.Item>responsable: <strong>{catedral[catedral.length - 1]?.responsable}</strong></ListGroup.Item>

            </Card.Body>
            <Card.Footer>
              <small className="text-muted">Last updated 3 mins ago</small>
            </Card.Footer>
          </Card>
          <Card>
            <Card.Img variant="top" src={picture5} width={180}
              height={300} />
            <Card.Body>
              <Card.Title>Sede Cartagena</Card.Title>

                <ListGroup.Item>id: <strong>{cartagena[cartagena.length - 1]?.id}</strong></ListGroup.Item>
                <ListGroup.Item>nombre: <strong>{cartagena[cartagena.length - 1]?.nombre}</strong></ListGroup.Item>
                <ListGroup.Item>cargo: <strong>{cartagena[cartagena.length - 1]?.cargo}</strong></ListGroup.Item>
                <ListGroup.Item>responsable: <strong>{cartagena[cartagena.length - 1]?.responsable}</strong></ListGroup.Item>

            </Card.Body>
            <Card.Footer>
              <small className="text-muted">Last updated 3 mins ago</small>
            </Card.Footer>
          </Card>
          <Card>

            <Card.Img variant="top" src={picture6} width={180}
              height={300} />
            <Card.Body>
              <Card.Title>Alemana Automotriz</Card.Title>

                <ListGroup.Item>id: <strong>{alemana[alemana.length - 1]?.id}</strong></ListGroup.Item>
                <ListGroup.Item>nombre: <strong>{alemana[alemana.length - 1]?.nombre}</strong></ListGroup.Item>
                <ListGroup.Item>cargo: <strong>{alemana[alemana.length - 1]?.cargo}</strong></ListGroup.Item>
                <ListGroup.Item>responsable: <strong>{alemana[alemana.length - 1]?.responsable}</strong></ListGroup.Item>

            </Card.Body>
            <Card.Footer>
              <small className="text-muted">Last updated 3 mins ago</small>
            </Card.Footer>
          </Card>
        </CardGroup>
        <br />
        <div align="center">
        <div className='bodega'>
            <Button variant="success" href='./Bodega'>
              Bodega <BsArchive style={{ fontSize: "30px", color: "white" }} />
            </Button>
          </div>
        </div>
          

        <hr /><br />

        <div style={{ backgroundColor: 'white', borderRadius: '10px' }}>
          <Card>
            <h1 align='center'>Observaciones de los usuarios</h1>
          </Card>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>id</th>
                <th>Observaciones</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td colSpan={3}>Lorem Lorem lorem q   klflsdjkfashndnljasdjlnasflaskldf</td>
              </tr>
              <tr>
                <td>2</td>
                <td colSpan={3}>Larry the Bird</td>
              </tr>
              <tr>
                <td>3</td>
                <td colSpan={3}>Larry the Bird</td>
              </tr>
            </tbody>
          </Table>
        </div>
      </div><br />
    </div>
  )
}

export default Principal